import * as React from 'react';
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
} from "@api-platform/admin";
import {
  TextInput,
} from 'react-admin';
import CustomImageInput from "../field/CustomImageInput";

const PickupLocation = {
  List: props => (
    <ListGuesser {...props}>
      <FieldGuesser source="name" label="Nom du lieu"/>
      <FieldGuesser source="address" label="Adresse"/>
    </ListGuesser>
  ),
  Create: props => (
    <CreateGuesser {...props}>
      <InputGuesser source="name" label="Nom du lieu"/>
      <TextInput multiline source="address" label="Adresse"/>
      <CustomImageInput {...props} source="picture" label="Image"/>
    </CreateGuesser>
  ),
  Edit: props => (
    <EditGuesser {...props}>
      <InputGuesser source="name" label="Nom du lieu"/>
      <TextInput multiline source="address" label="Adresse"/>
      <CustomImageInput {...props} source="picture" label="Image"/>
    </EditGuesser>
  )
};

export default PickupLocation;
