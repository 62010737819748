import * as React from 'react';
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
  Filter
} from "@api-platform/admin";
import {
  ArrayInput,
  CloneButton,
  NumberInput,
  ReferenceInput,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  Button,
  Link
} from 'react-admin';
import CustomImageInput from '../field/CustomImageInput';
import PromotionalOffers from "./promotionalOffers";
import ReferenceSelectionInput from "../field/ReferenceSelectionInput";
import Queue from '@material-ui/icons/Queue';

function omitId(record) {
  if (record['@id']) { delete record['@id']; }
  if (record['@context']) { delete record['@context']; }
  if (record['@type']) { delete record['@type']; }

  Object.keys(record).forEach(key => {
    if (record[key] && record[key]['@id']) {
      record[key] = omitId(record[key]);
    }

    if (Array.isArray(record[key])) {
      record[key] = omitId(record[key]);
    }
  });

  return record;
}

const CustomCloneButton = ({ record }) => {
  let entity = JSON.parse(JSON.stringify(record));

  if (entity.category && entity.category['@id']) { entity.category = entity.category['@id']; }
  if (entity.picture) { delete entity.picture; }
  if (entity.promotionalOffer) { delete entity.promotionalOffer; }
  if (entity.id) { delete entity.id; }
  if (entity.actualPrice) { delete entity.actualPrice; }

  entity = omitId(entity);

  return (
  <Button
    component={Link}
    to={{
      pathname: '/products/create',
      state: { record: entity },
    }}
    label='Dupliquer le produit'
    color='secondary'
  >
  <Queue />
  </Button>
);
};

const CustomFilters = props => (
  // <div>test</div>
  // <Filter {...props}>
    <ReferenceArrayInput label="Catégorie" source="category" reference="product_categories">
       <SelectArrayInput source="id"  optionText="name"/>
     </ReferenceArrayInput>
  // </Filter>
);

const Products = {
  List: props => {
    const EmbeddedPromotionalOffers = PromotionalOffers.EmbeddedPromotionalOffers;

    return (
      <ListGuesser {...props}>
        <FieldGuesser source="name" label="Nom du produit"/>
        <EmbeddedPromotionalOffers addLabel={true} label="Remise"/>
        <FieldGuesser source="category" label="Catégorie"/>
        <FieldGuesser source="unavailable" label="Indisponible"/>
      </ListGuesser>
    )
  },
  Create: props => (
    <CreateGuesser {...props}>
      <InputGuesser source="name" label="Nom du produit"/>
      <TextInput multiline source="description" label="Description"/>
      <CustomImageInput {...props} source="picture" label="Image"/>
      <InputGuesser source="price" label="Prix"/>
      <InputGuesser source="highlighted" label="Mis en avant"/>
      <InputGuesser source="unavailable" label="Indisponible"/>

      <ReferenceInput source="category" reference="product_categories">
        <ReferenceSelectionInput {...props} options={{label: 'Catégorie de produits'}}/>
      </ReferenceInput>

      <ArrayInput source="accompanimentCategories" reference="accompaniment_categories"
                  label="Catégorie d'accompagnements">
        <AccompanimentCategories/>
      </ArrayInput>
    </CreateGuesser>
  ),
  Edit: props => {
    return (
      <EditGuesser {...props}>
        <CustomCloneButton record={props.record}/>
        <InputGuesser source="unavailable" label="Indisponible"/>
        <InputGuesser source="name" label="Nom du produit"/>
        <TextInput multiline source="description" label="Description"/>
        <CustomImageInput {...props} source="picture" label="Image"/>
        <InputGuesser source="price" label="Prix"/>
        <InputGuesser source="highlighted" label="Mis en avant"/>

        <ReferenceInput source="category" reference="product_categories">
          <ReferenceSelectionInput {...props} options={{label: 'Catégorie de produits'}}/>
        </ReferenceInput>

        <ArrayInput source="accompanimentCategories" reference="accompaniment_categories"
                    label="Catégorie d'accompagnements">
          <AccompanimentCategories/>
        </ArrayInput>
      </EditGuesser>
    );
  }
};

const AccompanimentCategories = props => {
  let inlineStyle = {float: 'left', marginLeft: '20px'};

  return (
    <SimpleFormIterator {...props}>

      <TextInput source="name" label="Nom de la catégorie"/>

      <NumberInput source="min" label={"Nombre min. à sélectionner"} style={inlineStyle}/>
      <NumberInput source="max" label={"Nombre max. à sélectionner"} style={inlineStyle}/>

      <ArrayInput {...props} source="accompaniments" label="Accompagnements">
        <SimpleFormIterator>

          <TextInput source="name" label="Nom" style={inlineStyle}/>
          <NumberInput source="price" label="Prix (supplément)" style={inlineStyle}/>

        </SimpleFormIterator>
      </ArrayInput>

    </SimpleFormIterator>
  );
}

export default Products;
