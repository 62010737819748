import React from "react";
import {Redirect, Route} from "react-router-dom";
import {
  HydraAdmin,
  fetchHydra as baseFetchHydra,
  // hydraDataProvider as baseHydraDataProvider
} from "@api-platform/admin";
import {Resource} from 'react-admin';
import baseHydraDataProvider from "./dataProvider";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import Users from "./entities/users";
import DailyProducts from "./entities/dailyProducts";
import Products from "./entities/products";
import PromotionalOffers from "./entities/promotionalOffers";
import Partners from "./entities/partners";
import PickupLocation from "./entities/pickupLocation";
import Carts from "./entities/carts";
import ProductCategories from "./entities/productCategories";
import frenchMessages from './frenchMessages';
import polyglotI18nProvider from 'ra-i18n-polyglot';

// Menu icons
import PinDropIcon from '@material-ui/icons/PinDrop';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import StorefrontIcon from '@material-ui/icons/Storefront';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import WorkIcon from '@material-ui/icons/Work';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {Authorization: `Bearer ${window.localStorage.getItem("token")}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, {headers: new Headers(fetchHeaders)})
  .then(
    ({api}) => ({api}),
    (result) => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route path="/" render={() => {
                if (window.localStorage.getItem("token")) {
                  if (!window.localStorage.getItem("token_is_new")) {
                    window.localStorage.removeItem("token");
                  }

                  return;
                }

                return <Redirect to="/login"/>;
              }}/>
            ],
          });

        default:
          return Promise.reject(result);
      }
    },
  );

export const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);
// export const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const updateFunction = (method) => (resource, params) => {
  function uploadImage(params, imageSource) {

    if (params.data[imageSource] && params.data[imageSource]['@id']) {
      params.data[imageSource] = params.data[imageSource]['@id'];
    }

    if (
      !params.data[imageSource]
      || (params.data[imageSource] && !params.data[imageSource].then)
    ) {
      // fallback to the default implementation
      return dataProvider[method](resource, params);
    }

    return params.data[imageSource].then(image => {
      return dataProvider[method](resource, {
        ...params,
        data: {
          ...params.data,
          [imageSource]: image,
        },
      });
    });
  }

  switch (resource) {
    case 'product_categories':
    case 'daily_products':
    case 'pickup_locations':
      return uploadImage(params, 'picture');
      break;
    case 'products':
      if (params.data.partner) {
        params.data.partner = params.data.partner['@id'] || params.data.partner;
      }
      if (params.data.category) {
        params.data.category = params.data.category['@id'] || params.data.category;
      }
      return uploadImage(params, 'picture');
      break;
    default:
      return dataProvider[method](resource, params);
  }
}

const myDataProvider = {
  ...dataProvider,
  create: updateFunction('create'),
  update: updateFunction('update')
};

const i18nProvider = polyglotI18nProvider(_ => frenchMessages);
// const i18nProvider = locale => messages[locale];

export default () => (
  <HydraAdmin dataProvider={myDataProvider} authProvider={authProvider} entrypoint={entrypoint} i18nProvider={i18nProvider}>
    <Resource options={{label: 'Offres promotionnelles'}} name="promotional_offers" list={PromotionalOffers.List}
              create={PromotionalOffers.Create} edit={PromotionalOffers.Edit} icon={LocalOfferIcon}/>
    <Resource options={{label: 'Catégories de produits'}} name="product_categories" list={ProductCategories.List}
              create={ProductCategories.Create} edit={ProductCategories.Edit} icon={StorefrontIcon}/>
    <Resource options={{label: 'Produits'}} name="products" list={Products.List} create={Products.Create}
              edit={Products.Edit} icon={FastfoodIcon}/>
    <Resource options={{label: 'Menu de la semaine'}} name="daily_products" list={DailyProducts.List} create={DailyProducts.Create}
              edit={DailyProducts.Edit} icon={MenuBookIcon}/>
    <Resource options={{label: 'Partenaires'}} name="partners" list={Partners.List} create={Partners.Create}
              edit={Partners.Edit} icon={WorkIcon}/>
    <Resource options={{label: 'Points de retrait'}} name="pickup_locations" list={PickupLocation.List} create={PickupLocation.Create}
              edit={PickupLocation.Edit} icon={PinDropIcon}/>
    <Resource options={{label: 'Commandes'}} name="carts" list={Carts.List} show={Carts.Show} icon={ReceiptIcon}/>
    {/*<Resource options={{label: 'Utilisateurs'}} name="users" list={Users.List} show={Users.Show}*/}
    {/*          create={Users.Create} edit={Users.Edit} icon={PersonIcon}/>*/}
    <Resource name="accompaniment_categories"/>
  </HydraAdmin>
);
